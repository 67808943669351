<template>
	<div id="body">
		<div id="wx-banner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/zsgj.jpg" >
		</div>
		<div id="wx_tit">
			<div id="wx_tit_header">
				<p>店铺管理，尽在掌中</p>
				<span>融恒上管家让您把店铺的销售数据，会员信息，库存明细尽在掌中，让您实现把店铺管理放在口袋中</span>
			</div>
			<div id="wx-img">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gj01.jpg" >
			</div>
			<div id="wx_tit_main">
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='dianpu1'></icon-svg>
					<p>单店管理</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='dianpu'></icon-svg>
					<p>连锁管理</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='xiaoshoudingdan'></icon-svg>
					<p>销售数据</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='fuwu'></icon-svg>
					<p>会员管理</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='icon-p_dangqiankucun'></icon-svg>
					<p>库存查询</p>
				</div>
			</div>
			<div id="WX-shop" style="height: 600PX;padding-top: 10%;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjmb.jpg" >
				<div id="WX-shop_NAME">
					<!-- <p id="sub">微信店铺</p> -->
					<p id="Ti">会员管理</p>
					<span> 线下门店会员实时同步手机移动端，让您可通过掌上管家APP实时查看会员信息，会员实时销费明细等会员的相关数据</span>
				</div>
			</div>
			<div id="WX-shop">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjyg.jpg" >
				<div id="WX-shop_NAME">
					<p id="Ti">员工提成管理</p>
					<span>员工提成管理，通过手机端可实时统计查看店里每个业务员的工作情况，提成明细结合后台强大的提成设置，可在手机端完美查看每个员工的业绩状况提成明细</span>
				</div>
			</div>
			<div id="WX-shop" style="height: 600PX;margin-top: -140px;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/gjkc.jpg" >
				<div id="WX-shop_NAME">
					<p id="Ti">库存管理</p>
					<span>可让您实时了解仓库中商品数量的明细数据，以及提醒您哪些商品过了预警线，提示您需要补货</span>
				</div>
			</div>
			<div id="paybgc">
				<i class="el-icon-circle-check"></i>
				<p>可实时对接店铺收银系统</p>
				<span>让您把店铺的经营放在掌中实时了解店铺的销售、采购，会员、营销等一些关键性的数据全面提高您的管理效率。</span>
			</div>
		</div>
		<div id="wshopbox">
			<p id="on_wx">限时免费体验掌上管家</p>
			<p id="tw_wx">你手机里的移动店铺，在线掌握店铺一手数据</p>
			<p id="tw_wx">会员管理 | 销售管理 | 营销管理 | 库存管理</p>
			<p id="tw_wx">￥0</p>
			<button @click="tyfun" type="button">免费体验</button>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default{
		components: {
			footers
		},
		methods:{
			tyfun(){
				this.$router.push('/downAPP')
				
			}
			
		}
		}
</script>

<style scoped="scoped" lang="less">
	#wx-banner{
		width: 100%;
		height: 200px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	#wx_tit{
		width: 100%;
		
		// background-color: #FF0000;
		text-align: center;
		padding-top: 5%;
		#wx_tit_header{
			width: 100%;
			height: 140px;
			p{
				font-size: 22px;
			}
			span{
				display: block;
				width: 90%;
				margin-left: 5%;
				font-size: 14px;
				color: #6f6f6f;
			}
		}
		#wx_tit_main{
			width: 100%;
			height: 200px;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			#wx_tit_main_box{
				width: 32%;
				height: 49%;
				margin-left: 1%;
				.iconclas{
					font-size: 30px;
				}
				p{
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
		#WX-shop{
			width: 100%;
			height: 700px;
			img{
				max-width: 100%;
				max-height: 80%;
			}
			#WX-shop_NAME{
				#sub{
					font-size: 20px;
					margin-top: 3%;
				}
				#Ti{
					font-size: 24px;
					margin-top: 10%;
				}
				span{
					display: block;
					font-size: 14px;
					width: 80%;
					margin-left: 10%;
				}
			}
		}
		#paybgc{
			width: 100%;
			height: 300px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phonepay.jpg');
			background-size: 100% 100%;
			color: #FFFFFF;
			text-align: center;
			padding-top: 20%;
			i{
				font-size: 50px;
				margin-bottom: 1rem;
			}
			p{
				font-size: 24px;
			}
			span{
				display: block;
				width: 80%;
				margin-left: 10%;
				font-size: 14px;
			}
		}
	}
	#wshopbox{
		width: 100%;
		height: 300px;
		background-color: #eaeaea;
		text-align: center;
		padding-top: 15%;
		#on_wx{
			font-size: 28px;
			color: #fd3f31;
		}
		#tw_wx{
			font-size: 17px;
		}
		button{
			width: 40%;
			height: 50px;
			background-color: #fd3f31;
			border-radius: 5px;
			color: #FFFFFF;
		}
	}
</style>
